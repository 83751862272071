import ApiMedia from "@/core/apis/media";

export default {
  uploadImage(data) {
    return ApiMedia().post("upload", data);
  },

  uploadMultipleImages(data) {
    return ApiMedia().post("cms/v2/file-manager/uploads", data);
  }
};
