export const HOME_SECTIONS = [
  { id: 0, name: "Banner", componentName: "HomeBanner" },
  { id: 1, name: "Danh mục", componentName: "HomeCategory" },
  { id: 2, name: "Bộ sưu tập", componentName: "HomeFolder" },
  { id: 3, name: "Khuyến mại nổi bật", componentName: "HomePromotion" },
  { id: 4, name: "FlashSale", componentName: "HomeFlashSale" },
  { id: 5, name: "Danh mục bài viết", componentName: "HomeArticleFolder" },
  { id: 6, name: "Sản phẩm nổi bật", componentName: "HomeOutstandingProduct" },
  { id: 7, name: "Feedback", componentName: "HomeFeedback" },
  { id: 8, name: "Tìm kiếm nhiều", componentName: "HomeTopKeywords" },
  {
    id: 9,
    name: "Tìm kiếm nhiều (trang tin tức)",
    componentName: "NewsTopKeywords"
  }
];

export const FOLDER_HEADER = [
  { text: "Tên folder", align: "start", sortable: false, value: "name" },
  {
    text: "Url Front Store",
    align: "start",
    sortable: false,
    value: "shared_url"
  }
];

export const PROMOTION_HEADER = [
  { text: "Tên Khuyến mại", align: "start", sortable: false, value: "name" },
  {
    text: "Url Front Store",
    align: "start",
    sortable: false,
    value: "slug"
  }
];

export const INIT_HOME_SETTING = {
  SITE: "THINKPRO",
  BANNER: {
    color: "#FFFFFF",
    title: "",
    thumbnail: null,
    cover: null,
    banner_url: "",
    button_url: "",
    button_text: "",
    description: "",
    button_color: "#1E88E5",
    background_color: "#1E88E5",
    is_show_cta_button: false,
    is_show_description: false,
    items: []
  },
  CATEGORY: {
    ids: [],
    title: "",
    type: "slide"
  },
  FOLDER: {
    ids: [],
    title: ""
  },
  PROMOTION: {
    ids: [],
    title: "Khuyến mại"
  },
  FLASH_SALE: {
    frame_image: "",
    is_show_frame: false
  },
  ARTICLE_FOLDER: {
    title: "Danh mục bài viết",
    items: []
  },
  OUTSTANDING_PRODUCT: {
    title: "Sản phẩm nổi bật",
    ids: [],
    items: []
  },
  FEEDBACK: {
    title: "",
    items: []
  },
  TOP_KEYWORDS: {
    title: "Tìm kiếm nhiều",
    items: []
  },
  NEWS_TOP_KEYWORDS: {
    title: "Tìm kiếm nhiều (trang tin tức)",
    items: []
  }
};
