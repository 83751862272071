/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import GuaranteeVoucherService from "../service/guarantee-voucher.service";
import router from "@/core/router";
import store from "@/core/store";
import Vue from "vue";
import SerialService from "@/modules/Goods/service/serial.service";
import { catchError } from "@/core/composables";
import { groupByProperty } from "@/core/utils";

const state = {
  currentImage: "",
  guaranteeVoucher: {
    serial_id: null,
    branch_id: null,
    assigned_to_id: null,
    tinh_trang_may: null,
    ly_do_huy: null,
    attached_file: null,
    step: 1,
    type: null,
    options: [],
    xuat_serial_ids: [],
    sc_fee: null,
    take_participant_id: null,
    payer: null,
    require_hardware: false,
    created_at: Date.now(),
    docs: [],
    due_date: null,
    files: []
  },
  guaranteeVouchers: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  dueDate: {
    expired: 0,
    due_today: 0,
    due_tomorrow: 0
  },
  searchedSerials: null,
  serialInfo: null,
  statusRequest: {
    message: null,
    value: null
  },
  outerSerial: null
};

const getters = {
  currentImage: state => state.currentImage,
  guaranteeVoucher: state => state.guaranteeVoucher,
  guaranteeVouchers: state => state.guaranteeVouchers,
  paginationInfo: state => state.paginationInfo,
  dueDate: state => state.dueDate,
  searchedSerials: state => state.searchedSerials,
  serialInfo: state => state.serialInfo,
  statusRequest: state => state.statusRequest,
  outerSerial: state => state.outerSerial
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_GUARANTEE_VOUCHER: (state, payload) => {
    state.guaranteeVoucher = payload;
  },

  SET_GUARANTEE_VOUCHERS: (state, payload) => {
    state.guaranteeVouchers = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_DUE_DATE: (state, payload) => {
    state.dueDate = payload;
  },

  SET_SEARCHED_SERIALS: (state, payload) => {
    state.searchedSerials = payload;
  },

  SET_SERIAL_INFO: (state, payload) => {
    state.serialInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },
  SET_OUTER_SERIAL: (state, payload) => {
    state.outerSerial = payload;
  },
  SET_STATE_VALUE: (state, payload) => {
    state[payload.key] = payload.value;
  }
};

const actions = {
  cancelGuaranteeVoucher: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-cancelGuaranteeVoucher" });

      const response = await GuaranteeVoucherService.cancelGuaranteeVoucher(
        payload
      );

      if (response.status === 200 && response.data) {
        // Set cancel status
        Vue.set(state.guaranteeVoucher, "huy_status", 1);

        commit("SET_STATUS_REQUEST", {
          value: "success-cancelGuaranteeVoucher"
        });
      }
    } catch (e) {
      catchError(e, commit, "cancelGuaranteeVoucher");
    }
  },

  createGuaranteeVoucher: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createGuaranteeVoucher" });

      const response = await GuaranteeVoucherService.createGuaranteeVoucher(
        payload
      );

      if (response.status === 201 && response.data.data) {
        // Set id
        Vue.set(state.guaranteeVoucher, "id", response.data.data.id);

        commit("SET_STATUS_REQUEST", {
          value: "success-createGuaranteeVoucher"
        });
      }
    } catch (e) {
      catchError(e, commit, "createGuaranteeVoucher");
    }
  },

  createOuterSerial: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createOuterSerial" });

      const response = await GuaranteeVoucherService.outerSerial(payload);

      if (response.status === 201 && response.data) {
        const data = {
          ...response.data.data,
          ...payload
        };
        commit("SET_OUTER_SERIAL", data);

        commit("SET_STATUS_REQUEST", { value: "success-createOuterSerial" });
      }
    } catch (e) {
      catchError(e, commit, "createOuterSerial");
    }
  },

  exportReportGuarantee: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportGuarantee" });

      const response = await GuaranteeVoucherService.exportReportGuarantee(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-exportReportGuarantee"
        });

        return response.data;
      }
    } catch (err) {
      catchError(err, commit, "exportReportGuarantee", { isRedirected: false });
    }
  },

  exportReportRepair: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportRepair" });

      const response = await GuaranteeVoucherService.exportReportRepair(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-exportReportRepair"
        });

        return response.data;
      }
    } catch (err) {
      catchError(err, commit, "exportReportRepair", { isRedirected: false });
    }
  },

  getGuaranteeVouchers: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getGuaranteeVouchers" });

      const response = await GuaranteeVoucherService.getGuaranteeVouchers(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_GUARANTEE_VOUCHERS", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_DUE_DATE", {
          expired: response.data.meta.expired ? response.data.meta.expired : 0,
          due_today: response.data.meta.due_today
            ? response.data.meta.due_today
            : 0,
          due_tomorrow: response.data.meta.due_tomorrow
            ? response.data.meta.due_tomorrow
            : 0
        });
        commit("SET_STATUS_REQUEST", { value: "success-getGuaranteeVouchers" });
      }
    } catch (e) {
      catchError(e, commit, "getGuaranteeVouchers");
    }
  },

  getGuaranteeVoucherById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getGuaranteeVoucherById"
      });

      const response = await GuaranteeVoucherService.getGuaranteeVoucherById(
        payload
      );

      if (response.status === 200 && response.data) {
        const guaranteeVoucher = response.data.data;

        guaranteeVoucher.service_supplier_id = parseInt(
          guaranteeVoucher.service_supplier_id
        );

        // Group xuat_serials by option_id and set result into options field
        if (
          guaranteeVoucher.xuat_serials &&
          guaranteeVoucher.xuat_serials.length > 0
        ) {
          const serialsArr = guaranteeVoucher.xuat_serials;
          const options = groupByProperty(serialsArr, "option_id");

          // Set options field into guarantee voucher
          guaranteeVoucher.options = await Promise.all(
            options.map(async item => {
              const allSerials = await SerialService.getSerialsByOptionId(
                item[0].option_id
              );

              return {
                id: item[0].option_id,
                SKU: item[0].option_sku,
                name: item[0].option_name,
                product_name: item[0].product_name,
                unit_sold_price:
                  guaranteeVoucher.type === 2
                    ? Number(item[0].bh_sc_unit_sold_price)
                    : 0,
                rebate_amount:
                  guaranteeVoucher.type === 2
                    ? Number(item[0].bh_sc_unit_sold_price) -
                      Number(item[0].bh_sc_sold_price)
                    : 0,
                serials: item,
                allSerials: allSerials.data.data
              };
            })
          );
        } else {
          guaranteeVoucher.options = [];
        }

        if (guaranteeVoucher.export_id && guaranteeVoucher.export) {
          await store.dispatch("SALE/setPaymentList", [
            {
              payment_type: 1,
              payment_amount: 0,
              payment_fee:
                parseInt(guaranteeVoucher.export.value) -
                guaranteeVoucher.export.khachhang_con_no,
              payment_account_id: null,
              shipping_partner: null,
              installment_method: null
            }
          ]);
        }

        // Set serial info
        if (guaranteeVoucher.serial) {
          guaranteeVoucher.serial_id = guaranteeVoucher.serial.id;
          commit("SET_SERIAL_INFO", guaranteeVoucher.serial);
          commit("SET_GUARANTEE_VOUCHER", guaranteeVoucher);
        }
        if (guaranteeVoucher.outer_serial) {
          commit("SET_OUTER_SERIAL", guaranteeVoucher.outer_serial);
          commit("SET_GUARANTEE_VOUCHER", guaranteeVoucher);
        }
        if (
          guaranteeVoucher.participants &&
          guaranteeVoucher.participants.length > 0
        ) {
          guaranteeVoucher.require_hardware =
            guaranteeVoucher.participants[0].role === "TECHNIQUE_HARDWARE";
          guaranteeVoucher.reception_technique = guaranteeVoucher
            .participants[0].approved_by_id
            ? guaranteeVoucher.participants[0].approved_by_id
            : "";
        }

        commit("SET_STATUS_REQUEST", {
          value: "success-getGuaranteeVoucherById"
        });
      }
    } catch (e) {
      catchError(e, commit, "getGuaranteeVoucherById");
    }
  },

  getSerialInfoBySerialNumber: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getSerialInfoBySerialNumber"
      });

      const response = await GuaranteeVoucherService.getSerialInfoBySerialNumber(
        payload
      );

      if (response.status === 200 && response.data) {
        if (response.data.data && response.data.data.product_info)
          commit("SET_SERIAL_INFO", response.data.data);
        else commit("SET_OUTER_SERIAL", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getSerialInfoBySerialNumber"
        });
      }
    } catch (e) {
      catchError(e, commit, "getSerialInfoBySerialNumber");
    }
  },

  resetGuaranteeVoucher: async ({ commit }) => {
    commit("SET_GUARANTEE_VOUCHER", {
      serial_id: null,
      branch_id: null,
      assigned_to_id: null,
      tinh_trang_may: null,
      ly_do_huy: null,
      attached_file: null,
      step: 1,
      type: null,
      options: [],
      xuat_serial_ids: [],
      sc_fee: null,
      reception_technique: null,
      payer: null,
      require_hardware: false,
      created_at: Date.now(),
      docs: [],
      due_date: null,
      files: []
    });
  },
  resetOuterSerial: async ({ commit }) => {
    commit("SET_OUTER_SERIAL", null);
  },

  searchGuaranteeInfo: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-searchGuaranteeInfo" });

      const response = await GuaranteeVoucherService.searchGuaranteeInfo(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_SEARCHED_SERIALS", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-searchGuaranteeInfo" });
      }
    } catch (e) {
      if (e.response && e.response.status === 500) {
        router.push({ name: "500" });
      } else if (e.response) {
        commit("SET_STATUS_REQUEST", {
          message: "Serial không tồn tại hoặc chưa được xuất bán.",
          value: "error-searchGuaranteeInfo"
        });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: "Lỗi tra cứu bảo hành.",
          value: "error-searchGuaranteeInfo"
        });
      }
    }
  },

  setGuaranteeVoucher: async ({ commit }, payload) => {
    commit("SET_GUARANTEE_VOUCHER", payload);
  },

  setSerialInfo: ({ commit }, payload) => {
    commit("SET_SERIAL_INFO", payload);
  },

  updateGuaranteeVoucher: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateGuaranteeVoucher" });

      const response = await GuaranteeVoucherService.updateGuaranteeVoucher(
        payload
      );

      if (response.status === 200 && response.data) {
        // Get Guarantee Vouchers
        // await store.dispatch("GUARANTEE_VOUCHER/getGuaranteeVouchers", payload);
        await store.dispatch(
          "GUARANTEE_VOUCHER/getGuaranteeVoucherById",
          payload.id
        );

        commit("SET_STATUS_REQUEST", {
          value: "success-updateGuaranteeVoucher"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateGuaranteeVoucher");
    }
  },

  uploadImage: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadImage" });

      const response = await GuaranteeVoucherService.uploadImage(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATE_VALUE", {
          key: "currentImage",
          value: response.data
        });
        commit("SET_STATUS_REQUEST", { value: "success-uploadImage" });
      }
    } catch (e) {
      catchError(e, commit, "uploadImage");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
