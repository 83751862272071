/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import AuthenticationService from "@/modules/Authentication/service";
import request from "axios";
import router from "@/core/router";
import store from "@/core/store";

const state = {
  statusRequest: {
    message: null,
    value: null
  },
  user: null
};

const getters = {
  statusRequest: state => state.statusRequest,
  user: state => state.user
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },
  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },
  SET_USER: (state, payload) => {
    state.user = payload;
  }
};

const actions = {
  getCurrentUser: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getCurentUser" });

      const response = await AuthenticationService.getCurrentUser();

      if (response.status === 200 && response.data.data) {
        await commit("SET_USER", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-login" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else if (e.response.status === 401) {
        await store.dispatch("AUTHENTICATION/logout");
      }
    }
  },
  login: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-login" });

      const response = await AuthenticationService.login(payload);

      if (response.status === 200) {
        // Set user token
        localStorage.setItem("_TPUT", `Bearer ${response.data.token}`);
        localStorage.setItem("_EXPIRED", response.data.expired);
        localStorage.setItem("TPSALE_TABS", JSON.stringify([]));
        localStorage.setItem("TPSALE_TAB_ACTIVE_INDEX", JSON.stringify(0));
        // Add the axios default header
        request.defaults.headers[
          "Authorization"
        ] = `Bearer ${response.data.token}`;
        // Get user
        await store.dispatch("AUTHENTICATION/getCurrentUser");
        commit("SET_STATUS_REQUEST", { value: "success-login" });
        // Redirect to dashboard
        router.push({ name: "dashboard" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-login"
        });
      }
      localStorage.removeItem("_TPUT");
      localStorage.removeItem("_EXPIRED");
    }
  },
  logout: ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-logout" });
      // Remove user token
      localStorage.removeItem("_TPUT");
      localStorage.removeItem("_EXPIRED");
      localStorage.removeItem("TPSALE_TABS");
      localStorage.removeItem("TPSALE_TAB_ACTIVE_INDEX");
      // Remove the axios default header
      delete request.defaults.headers["Authorization"];
      // Redirect to login
      router.push({ name: "login" });
      // Reset user data
      commit("SET_USER", null);
      commit("SET_STATUS_REQUEST", { value: "success-logout" });
    } catch (e) {
      commit("SET_STATUS_REQUEST", { value: "error-logout" });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
